<template>
	<main>
		<section class="service-area pb-70 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>Current Openings</h2>
							<p>
								Interested candidates may send their resume to
								<span class="creer_mailid">career@xtrm.co.in</span> or Call us
								on <span class="creer_mailid">9723 236208</span>
							</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-md-12 mb-30">
						<div class="container">
							<div
								class="panel-group"
								id="accordion"
								role="tablist"
								aria-multiselectable="true"
							>
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="headingOne">
										<h6 class="panel-title">
											<a
												role="button"
												data-toggle="collapse"
												data-parent="#accordion"
												href="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												Python/PHP Developer - 2 (M/F)
											</a>
										</h6>
									</div>
									<div
										id="collapseOne"
										class="panel-collapse collapse in"
										role="tabpanel"
										aria-labelledby="headingOne"
									>
										<div class="panel-body">
											<h6>Key Skills</h6>
											<ul>
												<li>1+ Year Experience in (Python and/or PHP)</li>
												<li>
													Hands on experience with Django for Python / Any MVC
													framework (CodeIgnitor, Laravel etc.) for PH
												</li>
												<li>
													Deep understanding of OO concepts, Database design,
													REST APIs, JSON & Service design.
												</li>
												<li>
													(+) Knowledge of RDBMS – MySQL, MSSQL, PostgreSQL Etc.
												</li>
											</ul>
											<h6>Job Description</h6>
											<ul>
												<li>
													Independently Design, code, test, debug and document
													software according to the functional requirements
												</li>
												<li>Analyse, diagnose and resolve errors</li>
												<li>
													Excellent communication skills both written and verbal
													with positive interactions with other team members
												</li>
											</ul>
											<h6>Educational Qualification</h6>
											<ul>
												<li>Graduate/Post-Graduate in any discipline</li>
												<li>
													Under-Graduate but with proficiency in key skills may
													also apply
												</li>
											</ul>
											<h6>Job Perks</h6>
											<ul>
												<li>Industry comparable salary</li>
												<li>Flexible Working Hours</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="headingTwo">
										<h6 class="panel-title">
											<a
												class="collapsed"
												role="button"
												data-toggle="collapse"
												data-parent="#accordion"
												href="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Fron-End Developer - 2 (M/F)
											</a>
										</h6>
									</div>
									<div
										id="collapseTwo"
										class="panel-collapse collapse"
										role="tabpanel"
										aria-labelledby="headingTwo"
									>
										<div class="panel-body">
											<h6>Key Skills</h6>
											<ul>
												<li>
													1+ Year Experience in JQuery/AngularJS/Angular/React
													for front-end development
												</li>
												<li>Knowledge of MVC fundamentals</li>
												<li>
													Deep understanding of Web Application Design & Service
													design.
												</li>
												<li>(+) Knowledge of HTML5, HTTP, REST, JSON, CSS</li>
											</ul>
											<h6>Job Description</h6>
											<ul>
												<li>
													Independently Design, code, test, debug and document
													software according to the functional requirements
												</li>
												<li>Analyse, diagnose and resolve errors</li>
												<li>
													Excellent communication skills both written and verbal
													with positive interactions with other team members
												</li>
											</ul>
											<h6>Educational Qualification</h6>
											<ul>
												<li>Graduate/Post-Graduate in any discipline</li>
												<li>
													Under-Graduate but with proficiency in key skills may
													also apply
												</li>
											</ul>
											<h6>Job Perks</h6>
											<ul>
												<li>Industry comparable salary</li>
												<li>Flexible Working Hours</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="headingThree">
										<h6 class="panel-title">
											<a
												class="collapsed"
												role="button"
												data-toggle="collapse"
												data-parent="#accordion"
												href="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Android Application Developer - 1 (M/F)
											</a>
										</h6>
									</div>
									<div
										id="collapseThree"
										class="panel-collapse collapse"
										role="tabpanel"
										aria-labelledby="headingThree"
									>
										<div class="panel-body">
											<h6>Key Skills</h6>
											<ul>
												<li>
													Expertise in Java and Android SDK, Android Studio.
												</li>
												<li>Understanding of OO concepts.</li>
												<li>Good Knowledge in Firebase push notification</li>
												<li>Knowledge of google,Resful API’s</li>
											</ul>
											<h6>Job Description</h6>
											<ul>
												<li>
													Independently Design, code, test, debug and document
													app according to the functional requirements
												</li>
												<li>Analyse, diagnose and resolve errors</li>
												<li>Publication of app in play store.</li>
											</ul>
											<h6>Educational Qualification</h6>
											<ul>
												<li>Graduate/Post-Graduate in any discipline</li>
												<li>
													Under-Graduate but with proficiency in key skills may
													also apply
												</li>
											</ul>
											<h6>Job Perks</h6>
											<ul>
												<li>Industry comparable salary</li>
												<li>Flexible Working Hours</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "career",
};
</script>

<style></style>
